<template>
	<div>
		<CompanyContact :loading-parent="loading" />
		<CompanyAdministrators :loading-parent="loading" />
		<CompanyCoupons :loading-parent="loading" />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	data() {
		return {
			loading: true
		}
	},
	components: {
		CompanyAdministrators: () => import('@/components/companies/CompanyAdministrators.vue'),
		CompanyContact: () => import('@/components/companies/CompanyContact.vue'),
		CompanyCoupons: () => import('@/components/companies/CompanyCoupons.vue')
	},
	computed: {
		...mapGetters({
			company: 'companies/company'
		})
	},
	created() {
		this.fetchCompany().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('companies', ['fetchCompany'])
	}
}
</script>
